import { useState, useEffect } from "react"
import { MdEdit, MdEditOff } from "react-icons/md";
import { Button, FormControl, IconButton, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { message, PermiteSoloNumeros, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { IoChevronDown, IoChevronForward, IoCloseSharp, IoSave } from "react-icons/io5";
import { Toaster } from "react-hot-toast";
import ImagesPreviewGiftCard from "../../components/home/ImagesPreviewGiftCard";
import { BsFileWordFill, BsGlobe } from "react-icons/bs";
import { SiWorldhealthorganization } from "react-icons/si";
import { FcGlobe } from "react-icons/fc";
import moment from 'moment';
import Spinner from "../../components/Spinner";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector, useDispatch } from "react-redux";
import { updateSubCategory, SET_TO_DEFAULT_RESPONSE } from "../../store/features/slices/categorySlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImagesPreview from "../../components/ImagesPreview";
import Switch from "../../components/switch/index.jsx";
import { CURRENCY } from '../../utils/Constants';

const UpdateSubCategoryGC = ({ socket, categoryId, categoryGC, HandleCloseEditCategoryGC }) => {
    const dispatch = useDispatch();
    const { isMutation } = useSelector((state) => state.categoryReducer);
    const [tipoentrega, SetTipoEntrega] = useState(false)
    const [enabled, setEnabled] = useState(false)
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [state, setState] = useState({
        _id: "",
        subcategoryId: "",
        name: "",
        discountuser: 0,
        discountvip: 0,
        costouser: 0,
        costoreseller: 0,
        tipoentrega: false,
        enabled: "",
        rating: 0,
        logo: "",
        location: "",
        url: "",
        currentlogo: "",
        created_at: "",
        last_update: "",
        typecategory: ""
    });

    const [preview, setPreview] = useState({
        logo: '',
    })

    const imageHandle = e => {
        if (e.target.files.length !== 0) {
            setState({ ...state, [e.target.name]: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, [e.target.name]: reader.result })
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const updateSubmit = async e => {
        e.preventDefault();
        if (state.location.trim().length > 0) {
            const contentState = editorState.getCurrentContent();
            if (contentState.hasText()) {
                const formData = new FormData();
                formData.append('data', JSON.stringify(state));
                formData.append('rawData', JSON.stringify(convertToRaw(contentState)));
                formData.append('image1', state.logo)
                await dispatch(updateSubCategory(formData));
            } else {
                message('Términos y condiciones de servicio', 'error', 4)
            }
        } else {
            message('Localización es requerido', 'error', 4)
        }
    }

    const HandleChangeEditCategoryGC = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }

    const OnChangeTipoEntrega = () => {
        SetTipoEntrega(!tipoentrega)
        setState({ ...state, ['tipoentrega']: !tipoentrega })
    }

    const OnChangeEnabled = () => {
        setEnabled(!enabled)
        setState({ ...state, ['enabled']: !enabled })
    }

    useEffect(() => {
        if (categoryGC) {
            const CategoryGCEdit = {
                _id: categoryId,
                subcategoryId: categoryGC._id,
                name: categoryGC.name,
                discountuser: Number(categoryGC.discountuser),
                discountvip: Number(categoryGC.discountvip),
                costouser: Number(categoryGC.costouser),
                costoreseller: Number(categoryGC.costoreseller),
                description: categoryGC.description,
                tipoentrega: categoryGC.tipoentrega,
                enabled: categoryGC.enabled,
                rating: Number(categoryGC.rating),
                logo: categoryGC.logo,
                url: categoryGC.url ? categoryGC.url : "",
                currentlogo: categoryGC.logo,
                location: categoryGC.location,
                created_at: categoryGC.created_at,
                last_update: categoryGC.last_update,
                typecategory: "GiftCard"
            }
            setState(CategoryGCEdit)
            SetTipoEntrega(categoryGC.tipoentrega)
            setEnabled(categoryGC.enabled)
            const rawContent = categoryGC.policy;
            const contentState = convertFromRaw(JSON.parse(rawContent));
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
        }
    }, [categoryGC]);

    return (
        <form className="w-full md:w-12/12" onSubmit={updateSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-5">
                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre de la sub categoría</label>
                    <input autoFocus name="name" className="custom-style-input"
                        autoComplete="off"
                        required
                        value={state.name}
                        type="text"
                        onChange={HandleChangeEditCategoryGC}
                    />
                </div>

                <div className="w-full relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Descuento (%)</label>
                    <input name="discountuser" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        value={state.discountuser}
                        type="number"
                        onChange={HandleChangeEditCategoryGC}
                        min={0}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        %
                    </div>
                </div>

                <div className="w-full relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Costo usuario</label>
                    <input name="costouser" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        type="number"
                        step={0.01}
                        value={state.costouser}
                        onChange={HandleChangeEditCategoryGC}
                        min={0}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        {CURRENCY}
                    </div>
                </div>

                <div className="w-full relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Costo reseller</label>
                    <input name="costoreseller" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        type="number"
                        step={0.01}
                        value={state.costoreseller}
                        onChange={HandleChangeEditCategoryGC}
                        min={0}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        {CURRENCY}
                    </div>
                </div>

                <div className="w-full relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Descuento VIP</label>
                    <input name="discountvip" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        value={state.discountvip}
                        type="number"
                        step={0.01}
                        onChange={HandleChangeEditCategoryGC}
                        onWheel={(e) => e.target.blur()}
                        min={0}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        {CURRENCY}
                    </div>
                </div>

                <div className="w-full md:col-span-3">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Localización</label>
                    <div className="relative w-full md:w-4/12">
                        <FormControl fullWidth>
                            <Select
                                required
                                name="location"
                                className="flex items-center"
                                variant="outlined"
                                value={state.location}
                                onChange={HandleChangeEditCategoryGC}
                            >
                                <MenuItem value={'Global'} key={'Global'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Global.svg`} className="mr-2 rounded-full" />Global</MenuItem>
                                <MenuItem value={'Latam'} key={'Latam'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Latam.svg`} className="mr-2 rounded-full" />Latam</MenuItem>
                                <MenuItem value={'Perú'} key={'Perú'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Perú.svg`} className="mr-2 rounded-full" />Perú</MenuItem>
                                <MenuItem value={'Brasil'} key={'Brasil'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Brasil.svg`} className="mr-2 rounded-full" />Brasil</MenuItem>
                                <MenuItem value={'Colombia'} key={'Colombia'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Colombia.svg`} className="mr-2 rounded-full" />Colombia</MenuItem>
                                <MenuItem value={'Turquía'} key={'Turquía'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Turquía.svg`} className="mr-2 rounded-full" />Turquía</MenuItem>
                                <MenuItem value={'Estados Unidos'} key={'Estados Unidos'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Estados Unidos.svg`} className="mr-2 rounded-full" />Estados Unidos</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="w-full md:col-span-3">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">Tipo de entrega <span className="font-semibold">{tipoentrega ? "automática" : "manual"}</span></label>
                    <div className="h-10 mt-3">
                        <Switch
                            value={tipoentrega}
                            onChange={OnChangeTipoEntrega}
                        />
                    </div>
                </div>

                <div className="w-full md:col-span-3">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{enabled ? "Si" : "No"}</span></label>
                    <div className="h-10 mt-3">
                        <Switch
                            value={enabled}
                            onChange={OnChangeEnabled}
                        />
                    </div>
                </div>

                <div class="w-full col-span-1 md:col-span-2 lg:col-span-1">
                    <label htmlFor="logo" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-brand-500 border-dashed rounded-lg cursor-pointer bg-brand-100 dark:bg-brand-900 hover:bg-brand-200 dark:hover:bg-brand-700">
                        <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                            <p class="mb-2 text-sm text-brand-900 dark:text-brand-200"><span class="font-semibold">Click para subir imagen logo </span> o arrastrar y soltar</p>
                            <p class="text-xs text-brand-900 dark:text-brand-200">PNG o JPG (MAX. 800x400px)</p>
                        </div>
                        <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />

                    </label>

                </div>

                <div className="border-2 mb-5 w-full md:col-span-1 h-[250px] rounded-lg border-brand-500 border-dashed flex inline-flex items-center justify-center">
                    <div className="">
                        <LazyLoadImage
                            className="object-cover w-[150px] h-[150px] z-24 rounded-full flex inline-flex"
                            alt={`No image`}
                            effect="opacity"
                            src={`/images/logos/${state.currentlogo}`}
                        />
                    </div>
                    {preview.logo && <IoChevronForward className="text-brand-300" size={32} />}
                    <ImagesPreview url={preview.logo} />
                </div>

                <div className="w-full md:w-[calc(50%-10px)] md:col-span-3">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Términos y condiciones del servicio</label>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        placeholder="Escribe aquí..."
                    />
                </div>

                <div className="w-full md:w-[calc(33.33%-10px)] md:col-span-3">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Sitio web</label>
                    <input name="url" className="custom-style-input"
                        autoComplete="off"
                        required
                        value={state.url}
                        type="text"
                        onChange={HandleChangeEditCategoryGC}
                    />
                </div>

                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Registro de subcategoria</label>
                    <Tooltip title={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MdEditOff size={18} style={{ marginRight: 5 }} />
                            Registro de categoria no se puede editar
                        </div>}

                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    fontSize: 12.5,
                                }
                            }
                        }}
                        arrow>

                        <input name="created_at" className="custom-style-input"
                            autoComplete="off"
                            value={setUnixToTimeFormat("created_at", state.created_at)}
                            type="text"
                            disabled
                        />
                    </Tooltip>
                </div>

                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Ultima vez que se actualizó</label>
                    <Tooltip title={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MdEditOff size={18} style={{ marginRight: 5 }} />
                            Ultima vez que se actualizó no se puede editar
                        </div>}

                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    fontSize: 12.5,
                                }
                            }
                        }}
                        arrow>
                        <input name="last_update" className="custom-style-input"
                            autoComplete="off"
                            value={setUnixToTimeFormat("last_update", state.last_update)}
                            type="text"
                            disabled
                        />
                    </Tooltip>
                </div>
            </div>
            <div className="flex m-auto w-full">
                <Button
                    type="submit"
                    style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                    size='large' variant="contained"
                    disabled={isMutation.loading ? true : false}
                >
                    {isMutation.loading ? <Spinner w={'35px'} h={'35px'} /> : <IoSave size={24} className="mr-2" />}
                    {isMutation.loading ? 'Actualizando...' : 'Actualizar SUBCATEGORIA'}
                </Button>
            </div>
        </form>
    )
}
export default UpdateSubCategoryGC;