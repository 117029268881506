import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import Nav from "../../../components/home/Nav";
import { useForm } from "../../../hooks/Form";
import { Button, IconButton } from "@mui/material";
import { FaKey } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { IoEye, IoEyeOff, IoFingerPrint, IoLogIn, IoReloadSharp } from "react-icons/io5";
import { message } from "../../../utils/Funciones.js";
import Spinner from "../../../components/Spinner";
import { SET_TO_DEFAULT_RESPONSE, forgotPasswordRequest, login } from "../../../store/features/slices/userSlice";
import Modal from '../../../components/modal/Modal';
const moment = require("moment");
require("moment/locale/es");

const Login = ({ }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const recoverypassword = params.get("recoverypassword");
    const [recoverPassword, SetRecoverPassword] = useState({ open: false, email: '', randomkey: '', key: '' })

    const { isMutation, configuration, userToken } = useSelector((state) => state.userReducer);

    const { state, onChange } = useForm({
        email: '',
        password: ''
    })

    const onSubmit = e => {
        e.preventDefault();
        state.adminlogin = false
        if (userToken) {
            navigate('/');
        } else {
            if (configuration?.login) {
                dispatch(login(state))
            } else {
                message('el login se encuentra en mantenimiento, por favor reintenta en unos minutos.', 'error', 5)
            }
        }
    }

    const [VerPasswords, SetVerPasswords] = useState(
        {
            password: false,
        });


    const OpenRecoverPassword = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        const charactersLength = characters.length;
        let result = "";
        for (let i = 0; i < 5; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        SetRecoverPassword({ ...recoverPassword, open: true, randomkey: result })
    }

    const HandleChangeRandomKey = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        const charactersLength = characters.length;
        let result = "";
        for (let i = 0; i < 5; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        SetRecoverPassword({ ...recoverPassword, randomkey: result })
    }

    const SubmitRecoveryPassword = (e) => {
        e.preventDefault()
        var validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        if (configuration?.canrecoverpassword) {
            if (recoverPassword.email.length < 1) {
                message('El e-mail es requerido', 'error', 4)
            } else if (!validEmail.test(recoverPassword.email)) {
                message('El e-mail ingresado tiene un formato incorrecto', 'error', 4)
            } else if (recoverPassword.key.length < 1) {
                message('El código de verificación es requerido', 'error', 4)
            } else if (recoverPassword.randomkey !== recoverPassword.key) {
                message('El código de verificación ingresado es incorreccto', 'error', 4)
            } else {
                dispatch(forgotPasswordRequest({ email: recoverPassword.email }))
            }
        } else {
            message('recuperación de contraseña temporalmente bloqueado, reintentalo denuevo mas tarde.', 'error', 4)
        }
    }

    useEffect(() => {
        if (recoverypassword) {
            if (recoverypassword === "true") {
                OpenRecoverPassword()
            }
        }
    }, [recoverypassword])

    useEffect(() => {
        if (isMutation.updated) {
            message(isMutation.message, 'success', 5)
            recoverPassword.open && SetRecoverPassword({ ...recoverPassword, open: false, randomkey: '', email: '', key: '' })
            dispatch(SET_TO_DEFAULT_RESPONSE())
        }
    }, [isMutation.updated])

    useEffect(() => {
        if (isMutation.error) {
            if (isMutation.extra?.error === 'not found') {
                dispatch(SET_TO_DEFAULT_RESPONSE())
                navigate('/register');
            }
        }
    }, [isMutation.error])

    return (
        <>
            <Nav />
            <Modal
                open={recoverPassword.open}
                onClose={() => SetRecoverPassword({ ...recoverPassword, open: false, randomkey: '', email: '', key: '' })}
                headerTitle={`Recuperar contraseña`}
                size='sm'
            >
                <div className="relative w-full">
                    <form onSubmit={SubmitRecoveryPassword} >
                        <div class="divide-y divide-brand-200">
                            <div class="py-8 text-base leading-6 space-y-4 text-brand-700 sm:text-lg sm:leading-7">
                                <div class="relative">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Escribe tu e-mail de recuperación</label>
                                    <input autoFocus name="email" className="custom-style-input pl-10"
                                        autoComplete="off"
                                        required
                                        type="email"
                                        value={recoverPassword.email}
                                        onChange={(e) => SetRecoverPassword({ ...recoverPassword, email: e.target.value })}
                                    />
                                    <div class="absolute inset-y-4 mt-7 left-0 pr-3 flex items-center leading-5">
                                        <MdMail size={24} className="text-brand-800 dark:text-brand-200 ml-2" />
                                    </div>
                                </div>
                                <div class="relative">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Escribe código de verificación</label>
                                    <input name="key" className="custom-style-input pl-10"
                                        autoComplete="off"
                                        required
                                        type="text"
                                        value={recoverPassword.key}
                                        onChange={(e) => SetRecoverPassword({ ...recoverPassword, key: e.target.value })}
                                    />
                                    <div class="absolute inset-y-4 mt-7 left-0 pr-3 flex items-center text-sm leading-5">
                                        <FaKey size={24} className="text-brand-900 dark:text-brand-200 ml-2" />
                                    </div>
                                </div>
                                <div className="w-full md:w-12/12 text-center text-6xl relative">
                                    <div className="w-64 left-[calc(50%-120px)] h-[5px] absolute bg-brand-100 dark:bg-brand-950 backdrop-blur-xs top-10 rotate-12"></div>
                                    <div className="w-64 left-[calc(50%-120px)] h-[5px] absolute bg-brand-100 dark:bg-brand-950 top-10 -rotate-12"></div>
                                    <div className="w-64 left-[calc(50%-120px)] h-[8px] absolute bg-brand-100 dark:bg-brand-950 top-9"></div>
                                    <div className="p-4 rounded-lg text-brand-800 dark:text-brand-300 bg-brand-50 dark:bg-brand-950 uppercase tracking-wider" onCopy={HandleChangeRandomKey}>{recoverPassword.randomkey}</div>
                                    <button className="absolute right-0 bottom-1 text-lg flex items-center text-brand-950 dark:text-brand-50 bg-brand-100 dark:bg-brand-900 shadow-lg rounded-lg p-2" onClick={HandleChangeRandomKey} type="button">
                                        <IoReloadSharp />
                                    </button>
                                </div>
                                <div className="w-full">
                                    <Button
                                        sx={{
                                            "&.Mui-disabled": {
                                                background: "#525252",
                                                color: "#a3a3a3"
                                            }
                                        }}
                                        disabled={isMutation.uloading ? true : false}
                                        type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                        size='large' variant="contained" color="primary" startIcon={isMutation.uloading ? <Spinner w={'35px'} h={'35px'} /> : <IoReloadSharp size={24} />}>{isMutation.uloading ? 'Procesando...' : 'RECUPERAR CONTRASEÑA'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <div class="min-h-screen bg-brand-50 dark:bg-brand-950 justify-center sm:py-12">
                <div class="relative py-3 px-4 sm:max-w-xl sm:mx-auto">
                    <div
                        class="absolute inset-0 bg-gradient-to-r from-lightPrimary/70 to-darkPrimary shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
                    </div>
                    <div class="relative px-4 py-10 bg-brand-50 dark:bg-brand-950 shadow-lg sm:rounded-3xl sm:p-20">

                        <div class="w-full">
                            <div>
                                <h1 class="text-2xl font-semibold text-brand-950 dark:text-brand-50">Ingresar</h1>
                            </div>
                            <form onSubmit={onSubmit} >
                                <div class="divide-y divide-brand-200">
                                    <div class="py-8 text-base leading-6 space-y-4 text-brand-700 sm:text-lg sm:leading-7">
                                        <div class="relative">
                                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Correo</label>
                                            <input autoFocus name="email" className="custom-style-input pl-12"
                                                autoComplete="off"
                                                required
                                                value={state.email}
                                                onChange={onChange}
                                                type="email"
                                            />
                                            <div class="absolute inset-y-4 mt-7 left-0 pr-3 flex items-center leading-5">
                                                <MdMail size={28} className="text-brand-800 dark:text-brand-200 ml-3" />
                                            </div>
                                        </div>
                                        <div class="relative">
                                            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Password</label>
                                            <input name="password" className="custom-style-input pl-12"
                                                autoComplete="off"
                                                required
                                                onChange={onChange}
                                                value={state.password}
                                                type={VerPasswords.password ? 'text' : 'password'}
                                            />
                                            <div class="absolute inset-y-4 mt-7 left-0 pr-3 flex items-center text-sm leading-5">
                                                <IoFingerPrint size={28} className="text-brand-900 dark:text-brand-200 ml-3" />
                                            </div>
                                            <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                                {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['password']: !VerPasswords.password })}>
                                                    {VerPasswords.password ? <IoEyeOff className="text-brand-900 dark:text-brand-200" /> : <IoEye className="text-brand-900 dark:text-brand-200" />}
                                                </IconButton>}
                                            </div>
                                        </div>
                                        <div class="relative">
                                            <Button
                                                type="submit"
                                                disabled={isMutation.sloading ? true : false}
                                                style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                                                size='large' variant="contained"
                                            >
                                                {isMutation.sloading ? <Spinner /> : <IoLogIn size={24} className="mr-2" />}
                                                {isMutation.sloading ? 'Ingresando...' : 'INGRESAR'}
                                            </Button>
                                        </div>
                                        <div className="text-base mt-10 text-brand-400 m-auto w-full text-center">
                                            <span className="text-brand-800 dark:text-brand-200 cursor-pointer" onClick={OpenRecoverPassword} >
                                                ¿Olvidaste tu contraseña?
                                            </span>
                                        </div>

                                        <div className="text-base mt-4 text-brand-400 m-auto w-full text-center">No estás registrado ?
                                            <span className="text-brand-800 dark:text-brand-200 ml-2">
                                                <Link to="/register">
                                                    Registrarse
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login;