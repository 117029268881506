import { useState, useEffect, useMemo } from "react"
import { MdEdit, MdEditOff } from "react-icons/md";
import { Button, FormControl, IconButton, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { message, PermiteSoloNumeros, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { IoChevronDown, IoChevronForward, IoCloseSharp, IoSave } from "react-icons/io5";
import { Toaster } from "react-hot-toast";
import ImagesPreview from "../../components/ImagesPreview";
import { BsCalendar2Date, BsCalendar3 } from "react-icons/bs";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import moment from 'moment';
import 'moment/locale/es';
import draftToHtml from "draftjs-to-html";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Spinner from "../../components/Spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector, useDispatch } from "react-redux";
import { updateSubCategory, SET_TO_DEFAULT_RESPONSE } from "../../store/features/slices/categorySlice";
import Switch from "../../components/switch/index.jsx";
import { CURRENCY } from '../../utils/Constants';

const UpdateSubCategorySTR = ({ socket, categoryId, category }) => {
    const dispatch = useDispatch();
    const { isMutation } = useSelector((state) => state.categoryReducer);
    const [tipoentrega, SetTipoEntrega] = useState(false)
    const [enabled, setEnabled] = useState(false)
    const [cuentacompleta, SetCuentaCompleta] = useState(false)
    const [renovable, SetRenovable] = useState(false)
    const [profileblocked, SetProfileBlocked] = useState(false)
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [state, setState] = useState({
        _id: "",
        subcategoryId: "",
        name: "",
        maximoperfiles: "",
        costouser: "",
        discountuser: 0,
        discountvip: 0,
        costoreseller: "",
        created_at: "",
        last_update: "",
        logo: "",
        currentlogo: "",
        tipoentrega: "",
        requiredemail: "",
        requireduser: "",
        requiredpassword: "",
        enabled: "",
        rating: "",
        url: '',
        isprofileblocked: "",
        cuentacompleta: "",
        renovable: "",
        location: '',
        typecategory: "",
        minmonths: "",
    });

    const [preview, setPreview] = useState({
        logo: '',
    })

    const imageHandle = e => {
        if (e.target.files.length !== 0) {
            setState({ ...state, [e.target.name]: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, [e.target.name]: reader.result })
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const updateSubmit = e => {
        e.preventDefault();
        if (state.location.trim().length > 0) {
            const contentState = editorState.getCurrentContent();
            if (contentState.hasText()) {
                const formData = new FormData();
                formData.append('data', JSON.stringify(state));
                formData.append('rawData', JSON.stringify(convertToRaw(contentState)));
                formData.append('image1', state.logo)
                dispatch(updateSubCategory(formData));
            } else {
                message('Términos y condiciones de servicio', 'error', 4)
            }
        } else {
            message('Localización es requerido', 'error', 4)
        }
    }

    const HandleChangeEditCategory = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }

    const OnChangeTipoEntrega = () => {
        if (state.requiredemail || state.requiredpassword || state.requireduser) {
            SetTipoEntrega(!tipoentrega)
            setState({ ...state, requiredemail: false, requiredpassword: false, requireduser: false, tipoentrega: !state.tipoentrega })
        } else {
            SetTipoEntrega(!tipoentrega)
            setState({ ...state, tipoentrega: !state.tipoentrega })
        }
    }

    const OnChangeEnabled = () => {
        setEnabled(!enabled)
        setState({ ...state, ['enabled']: !enabled })
    }

    const OnChangeCuentaCompleta = () => {
        if (state.maximoperfiles > 1) {
            message('No se puede editar cuando máximo de perfiles es mayor a 1', 'error')
        } else {
            SetCuentaCompleta(!cuentacompleta)
            setState({ ...state, ['cuentacompleta']: !cuentacompleta })
        }
    }

    const OnChangeRenovable = () => {

        SetRenovable(!renovable)
        setState({ ...state, ['renovable']: !renovable })

    }

    const OnChangeProfileBlocked = () => {

        SetProfileBlocked(!profileblocked)
        setState({ ...state, ['isprofileblocked']: !profileblocked })

    }

    useEffect(() => {
        if (category) {
            const CategoryEdit = {
                _id: categoryId,
                subcategoryId: category._id,
                name: category.name,
                maximoperfiles: category.maximoperfiles,
                costouser: category.costouser,
                discountuser: category.discountuser,
                discountvip: category.discountvip,
                costoreseller: category.costoreseller,
                created_at: category.created_at,
                last_update: category.last_update,
                logo: category.logo,
                rating: category.rating,
                tipoentrega: category.tipoentrega,
                requiredemail: category.requiredemail,
                requireduser: category.requireduser,
                requiredpassword: category?.requiredpassword,
                enabled: category.enabled,
                isprofileblocked: category.isprofileblocked,
                cuentacompleta: category.cuentacompleta ? category.cuentacompleta : false,
                renovable: category.renovable ? category.renovable : false,
                url: category.url,
                currentlogo: category.logo,
                location: category.location,
                typecategory: "Streaming",
                minmonths: category.minmonths
            }
            setState({ ...CategoryEdit })
            SetTipoEntrega(category.tipoentrega)
            setEnabled(category.enabled)
            SetCuentaCompleta(category.cuentacompleta ? category.cuentacompleta : false)
            SetRenovable(category.renovable ? category.renovable : false)
            SetProfileBlocked(category.isprofileblocked)

            const rawContent = category.policy;
            const contentState = convertFromRaw(JSON.parse(rawContent));
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
        }
    }, [category]);

    return (
        <form className="w-full" onSubmit={updateSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mb-5">
                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre de la categoría</label>
                    <input autoFocus name="name" className="custom-style-input"
                        autoComplete="off"
                        required
                        value={state.name}
                        type="text"
                        onChange={HandleChangeEditCategory}
                    />
                </div>

                <div className="w-full">
                    <Tooltip title={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MdEditOff size={18} style={{ marginRight: 5 }} />
                            Máximo de perfiles no se puede editar
                        </div>}

                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    fontSize: 12.5,
                                }
                            }
                        }}
                        arrow>
                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Máximo de perfiles</label>
                        <input name="maximoperfiles" className="custom-style-input"
                            autoComplete="off"
                            disabled
                            required
                            value={state.maximoperfiles}
                            type="number"
                        /></Tooltip>
                </div>

                <div className="w-full relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Descuento de usuario (%)</label>
                    <input name="discountuser" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        value={state.discountuser}
                        type="number"
                        step={0.01}
                        onChange={HandleChangeEditCategory}
                        onWheel={(e) => e.target.blur()}
                        min={0}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        %
                    </div>
                </div>

                <div className="w-full relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Costo usuario</label>
                    <input name="costouser" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        value={state.costouser}
                        type="number"
                        step={0.01}
                        onChange={HandleChangeEditCategory}
                        onWheel={(e) => e.target.blur()}
                        min={0}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        {CURRENCY}
                    </div>
                </div>

                <div className="w-full relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Costo reseller</label>
                    <input name="costoreseller" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        value={state.costoreseller}
                        type="number"
                        step={0.01}
                        onChange={HandleChangeEditCategory}
                        onWheel={(e) => e.target.blur()}
                        min={0}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        {CURRENCY}
                    </div>
                </div>

                <div className="w-full relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Descuento VIP</label>
                    <input name="discountvip" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        value={state.discountvip}
                        type="number"
                        step={0.01}
                        onChange={HandleChangeEditCategory}
                        onWheel={(e) => e.target.blur()}
                        min={0}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        {CURRENCY}
                    </div>
                </div>

                <div className="w-full relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Cantidad mínima de meses</label>
                    <input name="minmonths" className="custom-style-input pl-8"
                        autoComplete="off"
                        required
                        value={state.minmonths}
                        type="number"
                        onChange={HandleChangeEditCategory}
                        onWheel={(e) => e.target.blur()}
                        min={1}
                    />
                    <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-2 flex items-center text-lg leading-5">
                        <BsCalendar3 size={20} className="text-brand-900 dark:text-brand-200" />
                    </div>
                </div>

                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Localización</label>
                    <div className="relative">
                        <FormControl fullWidth>
                            <Select
                                required
                                name="location"
                                className="flex items-center"
                                variant="outlined"
                                value={state.location}
                                onChange={HandleChangeEditCategory}
                            >
                                <MenuItem value={'Global'} key={'Global'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Global.svg`} className="mr-2 rounded-full" />Global</MenuItem>
                                <MenuItem value={'Latam'} key={'Latam'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Latam.svg`} className="mr-2 rounded-full" />Latam</MenuItem>
                                <MenuItem value={'Perú'} key={'Perú'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Perú.svg`} className="mr-2 rounded-full" />Perú</MenuItem>
                                <MenuItem value={'Brasil'} key={'Brasil'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Brasil.svg`} className="mr-2 rounded-full" />Brasil</MenuItem>
                                <MenuItem value={'Colombia'} key={'Colombia'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Colombia.svg`} className="mr-2 rounded-full" />Colombia</MenuItem>
                                <MenuItem value={'Turquía'} key={'Turquía'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Turquía.svg`} className="mr-2 rounded-full" />Turquía</MenuItem>
                                <MenuItem value={'Estados Unidos'} key={'Estados Unidos'} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/locations/Estados Unidos.svg`} className="mr-2 rounded-full" />Estados Unidos</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="w-full md:col-span-3">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado ? <span className="font-semibold">{enabled ? "Si" : "No"}</span></label>
                    <div className="h-10 mt-3">
                        <Switch
                            value={enabled}
                            onChange={OnChangeEnabled}
                        />
                    </div>
                </div>

                <div className="w-full md:col-span-3">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">Tipo de entrega <span className="font-semibold">{tipoentrega ? "automática" : "manual"}</span></label>
                    <div className="h-10 mt-3">
                        <Switch
                            value={tipoentrega}
                            onChange={OnChangeTipoEntrega}
                        />
                    </div>
                </div>

                <div className="w-full md:col-span-3">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">Se requiere <span className="font-semibold uppercase">correo</span> para completar pedido ? <span className="font-semibold">{state.requiredemail ? "Si" : "No"}</span></label>
                    <div className="h-10 mt-3">
                        <Switch
                            value={state.requiredemail}
                            onChange={() => {
                                if (tipoentrega) {
                                    SetTipoEntrega(false)
                                    if (state.requireduser) {
                                        setState({ ...state, tipoentrega: false, requireduser: false, requiredemail: !state.requiredemail })
                                    } else {
                                        setState({ ...state, tipoentrega: false, requiredemail: !state.requiredemail })
                                    }
                                } else {
                                    if (state.requireduser) {
                                        setState({ ...state, requireduser: false, requiredemail: !state.requiredemail })
                                    } else {
                                        setState({ ...state, requiredemail: !state.requiredemail })
                                    }
                                }
                            }}
                        />
                    </div>
                </div>


                <div className="w-full md:col-span-3">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">Se requiere <span className="font-semibold uppercase">usuario</span> para completar pedido ? <span className="font-semibold">{state.requireduser ? "Si" : "No"}</span></label>
                    <div className="h-10 mt-3">
                        <Switch
                            value={state.requireduser}
                            onChange={() => {
                                if (tipoentrega) {
                                    SetTipoEntrega(false)
                                    if (state.requiredemail) {
                                        setState({ ...state, tipoentrega: false, requireduser: !state.requireduser, requiredemail: false })
                                    } else {
                                        setState({ ...state, tipoentrega: false, requireduser: !state.requireduser })
                                    }
                                } else {
                                    if (state.requiredemail) {
                                        setState({ ...state, requireduser: !state.requireduser, requiredemail: false })
                                    } else {
                                        setState({ ...state, requireduser: !state.requireduser })
                                    }
                                }
                            }}
                        />
                    </div>
                </div>


                <div className="w-full md:col-span-3">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">Se requiere <span className="font-semibold uppercase">contraseña</span> para completar pedido ? <span className="font-semibold">{state.requiredpassword ? "Si" : "No"}</span></label>
                    <div className="h-10 mt-3">
                        <Switch
                            value={state.requiredpassword}
                            onChange={() => {
                                if (tipoentrega) {
                                    SetTipoEntrega(false)
                                    setState({ ...state, tipoentrega: false, requiredpassword: !state.requiredpassword })
                                } else {
                                    setState({ ...state, requiredpassword: !state.requiredpassword })
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="w-full md:col-span-3">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">Cuenta completa ? <span className="font-semibold">{cuentacompleta ? "Si" : "No"}</span></label>
                    <div className="h-10 mt-3">
                        <Switch
                            disabled={true}
                            value={cuentacompleta}
                            onChange={OnChangeCuentaCompleta}
                        />
                    </div>
                </div>

                <div className="w-full md:col-span-3">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">Es renovable ? <span className="font-semibold">{renovable ? "Si" : "No"}</span></label>
                    <div className="h-10 mt-3">
                        <Switch
                            value={renovable}
                            onChange={OnChangeRenovable}
                        />
                    </div>
                </div>

                <div className="w-full md:col-span-3">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">Se bloquea los perfiles ? <span className="font-semibold">{profileblocked ? "Si" : "No"}</span></label>
                    <div className="h-10 mt-3">
                        <Switch
                            disabled={true}
                            value={profileblocked}
                            onChange={OnChangeProfileBlocked}
                        />
                    </div>
                </div>

                <div class="w-full col-span-1 md:col-span-2 lg:col-span-1">
                    <label htmlFor="logo" class="flex flex-col items-center justify-center w-full h-[250px] border-2 border-brand-500 border-dashed rounded-lg cursor-pointer bg-brand-100 dark:bg-brand-900 hover:bg-brand-200 dark:hover:bg-brand-700">
                        <div class="flex flex-col items-center justify-center pt-5 pb-6 text-center">
                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                            <p class="mb-2 text-sm text-brand-900 dark:text-brand-200"><span class="font-semibold">Click para subir imagen logo </span> o arrastrar y soltar</p>
                            <p class="text-xs text-brand-900 dark:text-brand-200">PNG o JPG (MAX. 800x400px)</p>
                        </div>
                        <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />

                    </label>

                </div>

                <div className="border-2 mb-5 w-full md:col-span-1 h-[250px] rounded-lg border-brand-500 border-dashed flex inline-flex items-center justify-center">
                    <div className="">
                        <LazyLoadImage
                            className="object-cover w-[150px] h-[150px] z-24 rounded-full flex inline-flex"
                            alt={`No image`}
                            effect="opacity"
                            src={`/images/logos/${state.currentlogo}`}
                        />
                    </div>
                    {preview.logo && <IoChevronForward className="text-brand-300" size={32} />}
                    <ImagesPreview url={preview.logo} />
                </div>

                <div className="w-full md:col-span-2">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Términos y condiciones del servicio</label>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        placeholder="Escribe aquí..."
                    />
                </div>

                <div className="w-full md:w-[calc(33.33%-10px)] md:col-span-3">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Sitio web</label>
                    <input name="url" className="custom-style-input"
                        autoComplete="off"
                        required
                        value={state.url}
                        type="text"
                        onChange={HandleChangeEditCategory}
                    />
                </div>

                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Registro de subcategoria</label>
                    <Tooltip title={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MdEditOff size={18} style={{ marginRight: 5 }} />
                            Registro de categoria no se puede editar
                        </div>}

                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    fontSize: 12.5,
                                }
                            }
                        }}
                        arrow>

                        <input name="created_at" className="custom-style-input"
                            autoComplete="off"
                            value={setUnixToTimeFormat("created_at", state.created_at)}
                            type="text"
                            disabled
                        />
                    </Tooltip>
                </div>

                <div className="w-full">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Ultima vez que se actualizó</label>
                    <Tooltip title={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MdEditOff size={18} style={{ marginRight: 5 }} />
                            Ultima vez que se actualizó no se puede editar
                        </div>}

                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    fontSize: 12.5,
                                }
                            }
                        }}
                        arrow>
                        <input name="last_update" className="custom-style-input"
                            autoComplete="off"
                            value={setUnixToTimeFormat("last_update", state.last_update)}
                            type="text"
                            disabled
                        />
                    </Tooltip>
                </div>
            </div>
            <div className="flex m-auto w-full">
                <Button
                    type="submit"
                    disabled={isMutation.uloading ? true : false}
                    style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                    size='large' variant="contained"
                >
                    {isMutation.uloading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                    {isMutation.uloading ? 'Actualizando...' : 'Actualizar SubCategoria'}
                </Button>
            </div>
        </form>
    )
}
export default UpdateSubCategorySTR;