import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminLogin from "../screens/auth/AdminLogin";
import Categories from "../screens/admin/Categories";
import CreateCategory from "../screens/admin/CreateCategory";
import Cuentas from "../screens/admin/Cuentas";
import CreateCuenta from "../screens/admin/CreateCuenta";
import Private from "./Private.js";
import Public from "./Public";
import Home from "../screens/home/Home";
import Login from "../screens/home/auth/Login";
import Register from "../screens/home/auth/Register";
import Profile from "../screens/users/Profile";
import UserRoute from "./UserRoute";
import UserAuthRoute from "./UserAuthRoute";
import Recharge from "../screens/users/Recharge";
import StreamingOrders from "../screens/admin/StreamingOrders";
import GiftCardsOrders from "../screens/admin/GiftCardsOrders";
import History from "../screens/admin/History";
import CreateServiceSocial from "../screens/admin/CreateServiceSocial";
import UserOrders from "../screens/users/UserOrders";
import UserSocialOrders from "../screens/users/UserSocialOrders";
import Users from "../screens/admin/Users";
import UpdateUser from "../screens/admin/UpdateUser";
import CreateUser from "../screens/admin/CreateUser";
import Dashboard from "../screens/admin/Dashboard";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { currencyFormat, generateBackgroundColor, message, sleep } from "../utils/Funciones.js";
import toast from "react-hot-toast";
import { MdClose, MdInfo, MdWarning } from "react-icons/md";
import CategoriesGC from "../screens/admin/CategoriesGC";
import CreateGiftCard from "../screens/admin/CreateGiftCard";
import GiftCards from "../screens/admin/GiftCards";
import ForgotPasswordReset from "../components/home/ForgotPasswordReset";
import PaymentsHistory from "../screens/users/PaymentsHistory";
import Payments from "../screens/admin/Payments";
import { FaCheck } from "react-icons/fa";
import { FcLock } from "react-icons/fc";
import MyGiftCards from "../screens/users/MyGiftCards";
import Configuration from "../screens/admin/Configuration";
import SocialPlataforms from "../screens/admin/SocialPlataforms";
import SocialOrders from "../screens/admin/SocialOrders";
import {
  NEW_CATEGORY,
  NEW_SUBCATEGORY,
  UPDATE_CATEGORY,
  UPDATE_LAST10_OF_CATEGORY, UPDATE_SUBCATEGORY
} from "../store/features/slices/categorySlice";
import { LOGOUT, UPDATE_CONFIGURATION, UPDATE_USER_INFO } from "../store/features/slices/userSlice";
import MaintenancePage from "../components/MaintenancePage";
import Socket, { authSocket, logoutSocket } from "../utils/Socket";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaCoins } from "react-icons/fa6";
import { UPDATE_LAST10_OF_PLATAFORM } from "../store/features/slices/socialSlice";
import VerifyCode from "../components/home/VerifyCode";
import VerifyCodeNew from "../components/home/VerifyCodeNew";
import ErrorPage from "../components/ErrorPage";
import MyHistory from "../screens/users/MyHistory";

const Routing = ({ }) => {
  const dispatch = useDispatch();
  const { isMutation: isMutationUser, userToken, user, admin, adminToken, configuration, userInfo } = useSelector((state) => state.userReducer);
  const { isMutation: isMutationSocial } = useSelector((state) => state.socialReducer);
  const { isMutation: isMutationCategory } = useSelector((state) => state.categoryReducer);
  const { isMutation: isMutationHistory } = useSelector((state) => state.historyReducer);

  useEffect(() => {
    if (userToken) {
      if (user?.id) authSocket(userToken)
    } else if (adminToken) {
      if (admin?.id) authSocket(adminToken)
    }

    return () => {
      if (userToken) {
        logoutSocket(user?.id)
      } else if (adminToken) {
        logoutSocket(admin?.id)
      }
    }
  }, [user?.id, admin?.id, userToken, adminToken])

  useEffect(() => {
    Socket.on('uSubCategory', (data) => {
      dispatch(UPDATE_SUBCATEGORY(data))
    })

    Socket.on('uCategory', (data) => {
      dispatch(UPDATE_CATEGORY(data))
    })

    Socket.on('uSubCategoryWhitCategory', (data) => {
      dispatch(UPDATE_SUBCATEGORY({ newData: data.newData }))
      dispatch(UPDATE_LAST10_OF_CATEGORY(data.newHistory))
    })

    Socket.on('uHistoryPlataform', (data) => {
      dispatch(UPDATE_LAST10_OF_PLATAFORM(data.newHistory))
    })

    Socket.on('renewStreaming', (data) => {
      if (adminToken) {
        message(`el usuario ${data.user.email} ah renovado su servicio de ${data.subcategory.name}`, 'success', 5)
      }
      dispatch(UPDATE_SUBCATEGORY({ newData: data.subcategory }))
      dispatch(UPDATE_LAST10_OF_CATEGORY(data.newHistory))
    })

    Socket.on('newCategory', (data) => {
      dispatch(NEW_CATEGORY(data.newData))
    })

    Socket.on('newSubCategory', (data) => {
      dispatch(NEW_SUBCATEGORY(data.newData))
    })

    Socket.on('uConfiguration', (data) => {
      dispatch(UPDATE_CONFIGURATION(data.newConfiguration))
    })

    Socket.on('oLogout', () => {
      if (userToken) {
        dispatch(LOGOUT('userToken'))
      } else if (adminToken) {
        dispatch(LOGOUT('adminToken'))
      }
    })

    Socket.on('uUserInfo', (data) => {
      let userdata = data.newData
      let action = data.action
      if (action === 'addCash') {
        toast.custom((t) => (
          <div
            className={`${t.visible ? 'animate-enter' : 'animate-leave'
              } max-w-md w-full bg-brand-950 dark:bg-brand-50 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="w-[calc(100%-60px)] flex-1 w-0">
              <div className="flex flex-wrap">
                <div className="w-[65px] flex justify-center items-center pt-0.5">
                  {
                    Number(data.amount) > 0 ?
                      <div className="bg-green-600 text-white max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px] rounded-full flex items-center justify-center p-1">
                        <FaCoins size={24} />
                      </div>
                      :
                      <div className="bg-red-600 text-white max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px] rounded-full flex items-center justify-center p-1">
                        <FaCoins size={24} />
                      </div>
                  }
                </div>
                <div className="w-[calc(100%-65px)] flex-1 py-3 pr-1">
                  <p className="text-base font-medium text-brand-50 dark:text-brand-950">
                    Hola estimado(a) {userdata.name}, se ah {Number(data.amount) > 0 ? 'recargado' : 'penalizado'} <b>{Number(data.amount)}</b> créditos, {Number(data.amount) > 0 ? 'muchas gracias por su recarga.' : 'si tiene algún reclamo escribanos al whatsapp.'}
                  </p>
                  <p className="mt-1 text-base text-brand-50 dark:text-brand-950 opacity-60">
                    Que tenga buen día!
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[60px] flex border-l border-brand-800 dark:border-brand-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-red-500 dark:text-red-500 hover:text-red-400 focus:outline-none focus:ring-2 focus:ring-red-400"
              >
                Cerrar
              </button>
            </div>
          </div>
        ), { position: 'top-center', duration: Infinity })
      }
      dispatch(UPDATE_USER_INFO(userdata))
    })
  }, [])

  return (
    <div className="w-full bg-white dark:bg-black">
      {
        (isMutationUser.sloading) &&
        <div className="fixed inset-0 flex items-center justify-center bg-black dark:bg-black z-50 bg-opacity-70 backdrop-blur-[3px] dark:bg-opacity-40">
          <div className="flex flex-wrap justify-center gap-4 w-44">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-200 dark:border-gray-200"></div>
            <div>cargando sistema ...</div>
          </div>
        </div>
      }
      {
        !isMutationUser.sloading &&
        <BrowserRouter>
          <Routes>
            {/* Página 404 para rutas no existentes */}
            {(!configuration?.maintenance && !userInfo?.locked) && <Route path="*" element={<ErrorPage />} />}
            {
              configuration?.maintenance ?
                <Route path="*" element={<MaintenancePage />} />
                :
                userInfo?.locked ?
                  <Route path="*" element={
                    <div className="w-full h-screen flex items-center justify-center">
                      <div className="flex-wrap text-center text-red-500 text-2xl">
                        <FcLock size={128} className="m-auto" />
                        BLOQUEADO
                        <p className="w-full md:w-4/12 m-auto text-brand-800 dark:text-brand-200 text-sm mt-0 p-5">Su usuario ah sido bloqueado
                          por inclumplir nuestras reglas, para mayor información
                          comuniquese con atención al cliente, haz click en el icono de whatsapp que esta abajo a la derecha.</p>
                      </div>
                    </div>
                  } />
                  :
                  <>
                    <Route path="/" element={
                      <Home />} />

                    <Route path="code" element={<VerifyCode

                    />} />
                    <Route path="verifycodes24" element={<VerifyCodeNew

                    />} />

                    <Route element={<UserAuthRoute />}>
                      <Route path="forgotpasswordreset/:key" element={
                        <ForgotPasswordReset
                        />} />

                      <Route path="login" element={<Login

                      />} />
                      <Route path="register" element={<Register

                      />} />
                    </Route>
                    <Route element={<UserRoute />}>
                      <Route path="manage-account">
                        <Route path="profile" element={
                          <Profile


                          />} />
                        <Route path="myhistory" element={<MyHistory />} />

                        <Route path="userorders" element={
                          <UserOrders

                          />} />

                        <Route path="usersocialorders" element={
                          <UserSocialOrders

                          />} />

                        <Route path="mygiftcards" element={
                          <MyGiftCards

                          />} />
                        <Route path="paymentshistory" element={
                          <PaymentsHistory

                          />} />
                        <Route path="recharge" element={
                          <Recharge

                          />} />
                      </Route>
                    </Route>
                  </>
            }

            <Route path="auth">
              <Route
                path="admin-login"
                element={
                  <Public>
                    <AdminLogin
                    />
                  </Public>
                }
              />
            </Route>

            <Route path="admin">
              <Route
                path="dashboard"
                element={
                  <Private>
                    <Dashboard

                    />
                  </Private>
                }
              />

              <Route
                path="users"
                element={
                  <Private>
                    <Users

                    />
                  </Private>
                }
              />

              <Route
                path="update-user/:id"
                element={
                  <Private>
                    <UpdateUser

                    />
                  </Private>
                }
              />

              <Route
                path="create-user"
                element={
                  <Private>
                    <CreateUser

                    />
                  </Private>
                }
              />

              <Route
                path="cuentas"
                element={
                  <Private>
                    <Cuentas

                    />
                  </Private>
                }
              />

              <Route
                path="create-cuenta"
                element={
                  <Private>
                    <CreateCuenta

                    />
                  </Private>
                }
              />

              <Route
                path="categories"
                element={
                  <Private>
                    <Categories

                    />
                  </Private>
                }
              />

              <Route
                path="categoriesGC"
                element={
                  <Private>
                    <CategoriesGC

                    />
                  </Private>
                }
              />

              <Route
                path="create-category"
                element={
                  <Private>
                    <CreateCategory

                    />
                  </Private>
                }
              />

              <Route
                path="create-giftcard"
                element={
                  <Private>
                    <CreateGiftCard

                    />
                  </Private>
                }
              />

              <Route
                path="giftcards"
                element={
                  <Private>
                    <GiftCards

                    />
                  </Private>
                }
              />

              <Route
                path="streamingorders"
                element={
                  <Private>
                    <StreamingOrders

                    />
                  </Private>
                }
              />

              <Route
                path="giftcardsorders"
                element={
                  <Private>
                    <GiftCardsOrders

                    />
                  </Private>
                }
              />

              <Route
                path="socialorders"
                element={
                  <Private>
                    <SocialOrders

                    />
                  </Private>
                }
              />

              <Route
                path="payments"
                element={
                  <Private>
                    <Payments

                    />
                  </Private>
                }
              />


              <Route
                path="history"
                element={
                  <Private>
                    <History

                    />
                  </Private>
                }
              />

              <Route
                path="create-social-service"
                element={
                  <Private>
                    <CreateServiceSocial

                    />
                  </Private>
                }
              />

              <Route
                path="socialplataforms"
                element={
                  <Private>
                    <SocialPlataforms

                    />
                  </Private>
                }
              />

              <Route
                path="configuration"
                element={
                  <Private>
                    <Configuration

                    />
                  </Private>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      }
    </div>
  );
};
export default Routing;
