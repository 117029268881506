import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import Store from "./store"
import { Toaster } from 'react-hot-toast';

(function () {
  var _0x1b2c = ['hostname', 'alert', 'href', 'ab', 'out', ':', 'bla', 'nk', 'root'];
  //var _0x4f74 = ['l', 'o', 'c', 'a', 'l', 'h', 'o', 's', 't'];
  //var _0x4f74 = ['z', 'e', 't', 'a', 's', 't', 'r', 'e', 'a', 'm', 'i', 'n', 'g', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['m', 'e', 'g', 'a', '-', 's', 't', 'r', 'e', 'a', 'm', 'i', 'n', 'g', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['t', 'i', 'e', 'n', 'd', 'a', 'm', 'a', 'r', 's', 'a', 'e', 'n', 't', 'r', 'e', 't', 'e', 'n', 'i', 'm', 'i', 'e', 'n', 't', 'o', '.', 'c', 'o', 'm'];
  var _0x4f74 = ['v', 'i', 'r', 'a', 'l', 'b', 'o', 't', '2', '4', '.', 'o', 'n', 'l', 'i', 'n', 'e'];
  //var _0x4f74 = ['s', 't', 'r', 'e', 'a', 'm', 'i', 'n', 'g', 's', 'y', 'a', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['m', 'i', 'k', 'r', 'o', 's', 't', 'o', 'r', 'e', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['d', 'p', 's', 's', 'e', 'c', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['l', 'a', 't', 'i', 'n', '-', 'a', 'm', 'e', 'r', 'i', 'c', 'a', '-', 'c', 'h', 'i', 'l', 'e', '.', 'n', 'e', 't'];
  //var _0x4f74 = ['s', 't', 'r', 'e', 'a', 'm', 'i', 'n', 'g', 't', 'v', 'g', 'o', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['k', 'i', 'n', 'g', 's', 't','o', 'r', 'e', 'b', 'o', 'l','i', 'v', 'i', 'a', '.', 'c','o', 'm'];
  //var _0x4f74 = ['y', 'e', 'k', 'a', 's', 'a', 's', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['d', 'i', 'g', 'i', 'c', 'i', 't', 'y', 'v', 'i', 'p', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['a', 'l', 'i', 'e', 'n', 'b', 'o', 't', '.', 'l', 'a', 't'];
  //var _0x4f74 = ['m', 'y', 'm', 'u', 'n', 'd', 'o', 'f', 'l', 'i', 'x', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['s', 'o', 'c', 'i', 'a', 'l', 't', 'v', '.', 'o', 'n', 'l', 'i', 'n', 'e'];
  //var _0x4f74 = ['d', 'o', 'n', 'p', 'r', 'e', 'm', 'i', 'u', 'm', '.', 'o', 'r', 'g'];
  //var _0x4f74 = ['a', 'd', 'a', 'r', 'l', 'e', 'p', 'l', 'a', 'y', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['s', 'e', 'b', 'a', 's', '-', 's', 't', 'r', 'e', 'a', 'm', 'i', 'n', 'g', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['c', 'i', 'n', 'e', 'a', 'l', 'q', 'u', 'i', 'l', 'e', 'r', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['k', 'e', 'r', 'd', 'a', 'n', 's', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['e', 's', 'p', 'r', 'e', 'm', 'i', 'u', 'm', '.', 'l', 'a', 't'];
  //var _0x4f74 = ['t', 'v', 'c', 'u', 'e', 'n', 't', 'a', 's', 't', 'r', 'e', 'a', 'm', 'i', 'n', 'g', '.', 'c', 'o', 'm']
  //var _0x4f74 = ['e', 'x', 't', 'r', 'e', 'a', 'm', 'i', 'n', 'g', '.', 'l', 'a', 't'];
  //var _0x4f74 = ['c', 'a', 'x', 'a', 's', 'p', 'l', 'a', 'y', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['c', 'i', 'n', 'e', 'u', 'p', 'p', '.', 'c', 'o', 'm'];
  //var _0x4f74 = ['y', 'e', 'k', 'a', 's', 'a', 's', 't', 'r', 'e', 'a', 'm', 'i', 'n', 'g', '.', 's', 't', 'o', 'r', 'e'];
  //var _0x4f74 = ['m', 'i', 'c', 'a', 's', 'i', 't', 'a', 't', 'v', '.', 'c', 'o', 'm'];

  var _0x2f7c = _0x4f74.join('');
  var _0x9f7d = _0x2f7c;

  if (window.location[_0x1b2c[0]] !== _0x9f7d) {
    window.location[_0x1b2c[2]] = _0x1b2c[3] + _0x1b2c[4] + _0x1b2c[5] + _0x1b2c[6] + _0x1b2c[7];
  } else {
    ReactDOM.render(
      <Provider store={Store}>
        <Toaster position="top-right" reverseOrder={true} />
        <App />
      </Provider>,
      document.getElementById(_0x1b2c[8])
    );
  }
})();
reportWebVitals();
