import { applyTransparency, convertirsegundos, currencyFormat, generateBackgroundColor, message, sensurarEmail, sensurarString } from "../../utils/Funciones.js";
import { BsCash, BsHandbag, BsTagFill } from "react-icons/bs";
import { Button, Checkbox, FormControl, IconButton, MenuItem, Select, TextareaAutosize, Tooltip } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Spinner from "../Spinner.js";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import moment from 'moment';
import 'moment/locale/es';
import { useNavigate } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import parse from 'html-react-parser';
import { useDispatch, useSelector } from "react-redux";
import Modal from "../modal/Modal.jsx";
import { uriSerialized } from '../../utils/uriSerialized.js';
import { SET_CASH_USER, subscribevip, SET_TO_DEFAULT_RESPONSE } from "../../store/features/slices/userSlice.jsx";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket.js';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaCheck, FaCircleCheck, FaUser } from "react-icons/fa6";
import { RiVipCrownFill } from "react-icons/ri";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';


const SubscripcionVip = ({ socket }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken, user, userInfo, configuration, isMutation } = useSelector((state) => state.userReducer);

  const onPurchaseVip = (type, price) => {
    if (userToken) {
      alertaConfirmar(`COMPRAR VIP`, `Estás comprando una membresía VIP, quieres continuar ?`, 'question', (res_alert) => {
        if (res_alert === true) {
          dispatch(subscribevip({ userId: user?.id, viptype: type, price: price }))
        }
      })
    } else {
      navigate('/login');
    }
  }

  useEffect(() => {
    if (isMutation.success) {
      message(isMutation.message, 'success', 5)
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
  }, [dispatch, isMutation.success])

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }

  return (
    <>
      {
        (configuration?.vip?.vip1?.enabled || configuration?.vip?.vip2?.enabled || configuration?.vip?.vip3?.enabled) &&
        <>
          <div className="w-full 
       text-brand-900 dark:text-brand-100
        mb-3 mt-4 md:mt0 text-center text-xl md:text-3xl pt-5 pb-5 md:pt-14 md:pb-14 contrast-[125%]">
            <div style={{ fontFamily: 'Outfit, sans-serif' }}>{t('subsvip')} <label className="text-lightPrimary dark:text-darkPrimary">{t('savetomax')}</label></div>
            <div className="!text-base font-normal">{t('sloganvip')}!</div>
          </div>

          <div className="flex flex-wrap pb-10 justify-center">
            <div className="w-full md:w-full lg:w-9/12 min-h-[22rem] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-3 md:gap-x-8 gap-y-8 md:gap-y-14 mb-4">

              <div className="border-4 border-[#CD7F32] relative flex flex-wrap justify-center shadow-lg shadow-brand-300 dark:shadow-black bg-gradient-to-b from-black to-[#CD7F32] group w-full min-h-[500px] h-[500px] md:h-[500px] lg:h-[500px] rounded-3xl transition-transform ease-in-out duration-700 md:hover:scale-[115%] cursor-pointer">
                <div className="bg-red-500 absolute -top-8 p-2 rounded-lg text-white text-sm font-semibold">{t('moresales')}</div>
                <div className="w-[calc(100%-20px)] bg-white/20 dark:bg-black/20 flex flex-wrap justify-center rounded-b-lg p-2 h-[9rem]">
                  <div className="grid rounded-lg p-1">
                    <div style={{ fontFamily: 'Outfit, sans-serif' }} className="text-4xl text-center font-bold text-[#CD7F32] [text-shadow:0px_2px_2px_var(--tw-shadow-color)] shadow-black dark:shadow-black">
                      VIP
                    </div>
                    <div className="text-2xl text-center font-normal text-brand-50 uppercase">
                      1 {t('month')}
                    </div>
                  </div>
                  <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-full flex items-center justify-center text-3xl mt-1 text-brand-50">
                    {currencyFormat(configuration?.vip?.vip1?.price)}
                  </div>
                </div>
                <div className="flex w-full h-[4.5rem] p-3 text-center font-bold text-white">{configuration?.vip?.vip1?.eslogan}</div>
                <ul className="w-full h-[calc(100%-13.5rem)]">
                  {
                    configuration?.vip?.vip1?.features?.map((item) => {
                      return (
                        <li key={item._id} className="flex items-center text-[11px] ml-3 h-8 text-white">
                          <FaCircleCheck size={20} className="mr-1 text-[#CD7F32]" />
                          {item.name}
                        </li>
                      )
                    })
                  }
                </ul>
                <div className="absolute bottom-3">
                  <Button
                    onClick={() => onPurchaseVip(configuration?.vip?.vip1?.name, configuration?.vip?.vip1?.price)}
                    disabled={!configuration?.vip?.vip1?.enabled}
                    type="button" style={{ width: 160, height: 60, marginLeft: 'auto', marginRight: 'auto' }}
                    size='large' variant="contained" color="error" startIcon={(isMutation.loading) ? <Spinner w={'35px'} h={'35px'} /> : <RiVipCrownFill size={24} className="mr-2" />}>{isMutation.loading ? ' Procesando...' : 'Comprar'}
                  </Button>
                </div>
              </div>

              <div className="border-4 border-[#C0C0C0] relative flex flex-wrap justify-center shadow-lg shadow-brand-300 dark:shadow-black bg-gradient-to-b from-black to-[#C0C0C0] group w-full min-h-[500px] h-[500px] md:h-[500px] lg:h-[500px] rounded-xl transition-transform ease-in-out duration-700 md:hover:scale-[115%] cursor-pointer">
                <div className="w-[calc(100%-20px)] bg-white/20 dark:bg-black/20 flex flex-wrap justify-center rounded-b-lg p-2 h-[9rem]">
                  <div className="grid rounded-lg p-1">
                    <div style={{ fontFamily: 'Outfit, sans-serif' }} className="text-4xl text-center font-bold text-[#C0C0C0] [text-shadow:0px_2px_2px_var(--tw-shadow-color)] shadow-black dark:shadow-black">
                      VIP
                    </div>
                    <div className="text-2xl text-center font-normal text-brand-50 uppercase">
                      6 {t('months')}
                    </div>
                  </div>
                  <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-full flex items-center justify-center text-3xl mt-1 text-brand-50">
                    {currencyFormat(configuration?.vip?.vip2?.price)}
                  </div>
                </div>
                <div className="flex w-full h-[4.5rem] p-3 text-center font-bold text-white">{configuration?.vip?.vip2?.eslogan}</div>
                <ul className="w-full h-[calc(100%-13.5rem)]">
                  {
                    configuration?.vip?.vip2?.features?.map((item) => {
                      return (
                        <li key={item._id} className="flex items-center text-[11px] ml-3 h-8 text-white">
                          <FaCircleCheck size={20} className="mr-1 text-[#C0C0C0]" />
                          {item.name}
                        </li>
                      )
                    })
                  }
                </ul>
                <div className="absolute bottom-3">
                  <Button
                    onClick={() => onPurchaseVip(configuration?.vip?.vip2?.name, configuration?.vip?.vip2?.price)}
                    disabled={!configuration?.vip?.vip2?.enabled}
                    type="button" style={{ width: 160, height: 60, marginLeft: 'auto', marginRight: 'auto' }}
                    size='large' variant="contained" color="error" startIcon={(isMutation.loading) ? <Spinner w={'35px'} h={'35px'} /> : <RiVipCrownFill size={24} className="mr-2" />}>{isMutation.loading ? ' Procesando...' : 'Comprar'}
                  </Button>
                </div>
              </div>

              <div className="border-4 border-[#FFD700] relative flex flex-wrap justify-center shadow-lg shadow-brand-300 dark:shadow-black bg-gradient-to-b from-black to-[#FFD700] group w-full min-h-[500px] h-[500px] md:h-[500px] lg:h-[500px] rounded-xl transition-transform ease-in-out duration-700 md:hover:scale-[115%] cursor-pointer">

                <div className="w-[calc(100%-20px)] bg-white/20 dark:bg-black/20 flex flex-wrap justify-center rounded-b-lg p-2 h-[9rem]">
                  <div className="grid rounded-lg p-1">
                    <div style={{ fontFamily: 'Outfit, sans-serif' }} className="text-4xl text-center font-bold text-[#FFD700] [text-shadow:0px_2px_2px_var(--tw-shadow-color)] shadow-black dark:shadow-black">
                      VIP
                    </div>
                    <div className="text-2xl text-center font-normal text-brand-50 uppercase">
                      12 {t('months')}
                    </div>
                  </div>
                  <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-full flex items-center justify-center text-3xl mt-1 text-brand-50">
                    {currencyFormat(configuration?.vip?.vip3?.price)}
                  </div>
                </div>
                <div className="flex w-full h-[4.5rem] p-3 text-center font-bold text-white">{configuration?.vip?.vip3?.eslogan}</div>
                <ul className="w-full h-[calc(100%-13.5rem)]">
                  {
                    configuration?.vip?.vip3?.features?.map((item) => {
                      return (
                        <li key={item._id} className="flex items-center text-[11px] ml-3 h-8 text-white">
                          <FaCircleCheck size={20} className="mr-1 text-[#FFD700]" />
                          {item.name}
                        </li>
                      )
                    })
                  }
                </ul>
                <div className="absolute bottom-3">
                  <Button
                    onClick={() => onPurchaseVip(configuration?.vip?.vip3?.name, configuration?.vip?.vip3?.price)}
                    disabled={!configuration?.vip?.vip3?.enabled}
                    type="button" style={{ width: 160, height: 60, marginLeft: 'auto', marginRight: 'auto' }}
                    size='large' variant="contained" color="error" startIcon={(isMutation.loading) ? <Spinner w={'35px'} h={'35px'} /> : <RiVipCrownFill size={24} className="mr-2" />}>{isMutation.loading ? t('proccessing') + '...' : t('buy')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
};

export default SubscripcionVip;
